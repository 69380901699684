import React, {useEffect, useState} from 'react';
import {FormResponse, FormState, CompetitorStation, FetchingDataState, AgentStation} from "../../types";
import {Price} from "./Price";
import {Button} from "../Button/Button";
import {CompetitorStationDropdown} from "./CompetitorStationDropdown";
import {Header} from "../Header/Header";

import {fetchStationData, postStationForm} from "../../utils/async";

import './stationPriceForm.scss';
import {useAuth0} from "@auth0/auth0-react";
import {Alert} from "../Alert/Alert";
import {ButtonSpinner} from "../ButtonSpinner";
import {Spinner} from "../Spinner";
import {AgentStationDropdown} from "./AgentStationDropdown";
export const StationPriceForm = () => {

    const [formState, setFormState] = useState<FormState>({
        agentStation: undefined,
        competitorStation: undefined,
        competitorStationProducts: undefined
    })
    const [competitorStationsState, setCompetitorStationsState] = useState<CompetitorStation[]>(undefined)
    const [agentStationsState, setAgentStationsState] = useState<AgentStation[]>(undefined)

    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [isValidFormState, setIsValidFormState] = useState(false)
    const [invalidPriceInputIds, setInvalidPriceInputIds] = useState<string[]>([])
    const [postFormState, setPostFormState] = useState<FetchingDataState>({
        loading: false,
        success: false,
        error: false
    })
    const [fetchingStationsState, setFetchingStationsState] = useState<FetchingDataState>({
        loading: true,
        success: false,
        error: false
    })

    const showFormPostResult = postFormState.success || postFormState.error;
    const disabledSubmit = !isValidFormState || postFormState.loading || invalidPriceInputIds.length > 0;

    const { getAccessTokenSilently } = useAuth0();
    async function getAccessToken() : Promise<string> {
        return await getAccessTokenSilently();
    }

    useEffect(() => {
        getAccessToken().then(tokenResponse => {
             fetchStationData(tokenResponse, setCompetitorStationsState, setAgentStationsState, setFetchingStationsState)
        })
    }, [])

    useEffect(() => {
        const hasSelectedStation = formState.competitorStation !== undefined;
        const hasFilledOutAtLeastOneFuelPrice = formState.competitorStationProducts?.some(fuelPrice => fuelPrice.competitorStationProductId !== undefined)
        setIsValidFormState(hasSelectedStation && hasFilledOutAtLeastOneFuelPrice)
    }, [formState])

    const handleOnSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        const body: FormResponse = {
            competitorStationId: formState.competitorStation.id,
            competitorStationProducts: formState.competitorStationProducts.map(product => ({
              "competitorStationProductId": product.competitorStationProductId,
              "price": `${product.price}`
            }))
        }
        getAccessToken().then(tokenResponse => {
          postStationForm(tokenResponse, body, setPostFormState)
        })
    }

    if (competitorStationsState?.length == 0) {
        return (
          <div>
              <Header setIsOpen={setMenuIsOpen}/>
              <Alert
                text="Du er ikke tilknyttet noen konkurrentstasjoner. Vennligst be en administrator om å oppdatere innstillingene dine."
                color="orange"
                style="ml-2 mr-2"
              />
          </div>
        );
    }

    if(fetchingStationsState.loading) {
        return (
          <div className="main-container flex-col">
              <Header setIsOpen={setMenuIsOpen}/>
              <Spinner/>
          </div>
        );
    }

    if (fetchingStationsState.error) {
        return (
          <div>
              <Header setIsOpen={setMenuIsOpen}/>
              <Alert
                text="Det har oppstått en uforventet feil, vennligst last inn siden på nytt og prøv igjen. Ta kontakt med en administrator dersom problemet vedvarer."
                color="orange"
                style="ml-2 mr-2"
              />
          </div>
        );
    }

    return (
        <div className="station-price-form-container">
            <Header setIsOpen={setMenuIsOpen}/>
            <form
              className={`${menuIsOpen ? "hidden-menu" : "visible-menu"} station-price-form`}
              onSubmit={handleOnSubmit}
            >
                {agentStationsState &&
                  <AgentStationDropdown stations={agentStationsState} formState={formState} setFormState={setFormState}/>
                }
                {competitorStationsState &&
                  <CompetitorStationDropdown formState={formState} setFormState={setFormState} stations={competitorStationsState}/>
                }
                {formState.competitorStation && formState.competitorStation.priceForms?.map((priceForm, index) =>
                  <Price
                    key={index}
                    formState={formState}
                    setFormState={setFormState}
                    priceForm={priceForm}
                    invalidPriceInputIds={invalidPriceInputIds}
                    setInvalidPriceInputIds={setInvalidPriceInputIds}
                  />
                )}
                {showFormPostResult &&
                  <Alert
                    color={postFormState.success ? "green" : "orange"}
                    text={postFormState.success ? "Prisen er registrert." : "Det har oppstått en uforventet feil, vennligst last inn siden på nytt og prøv igjen. Ta kontakt med en administrator dersom problemet vedvarer."}
                  />
                }
                <Button type="submit" disabled={disabledSubmit}>
                    <span>Send inn </span>
                    {postFormState.loading && <ButtonSpinner/>}
                </Button>
                
            </form>
        </div>
    );
}