import React, {Dispatch, FunctionComponent, SetStateAction, useEffect, useState} from 'react';
import {ErrorState, FormState, PriceForm} from "../../types";
import {formatPrice, isValidPrice, validatePrice} from "../../utils/helpers";
import {ExclamationCircleIcon} from "@heroicons/react/solid";

interface PriceProps {
    formState: FormState;
    setFormState: Dispatch<SetStateAction<FormState>>
    priceForm: PriceForm;
    invalidPriceInputIds: string[];
    setInvalidPriceInputIds: Dispatch<SetStateAction<string[]>>
}

export const Price: FunctionComponent<PriceProps> = ({formState, setFormState, priceForm, invalidPriceInputIds, setInvalidPriceInputIds}) => {

    const [priceValue, setPriceValue] = useState("")
    const [errorState, setErrorState] = useState<ErrorState>({
        isError: false,
        errorMessage: undefined
    })

    useEffect(() => {
        setPriceValue("")
    }, [formState.competitorStation])

    const handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
        const input = event.currentTarget.value
        const formattedPrice = formatPrice(input)
        const otherStationProducts = formState.competitorStationProducts?.filter(product => product.competitorStationProductId !== priceForm.id) ?? []
        setPriceValue(formattedPrice)
        if(isValidPrice(formattedPrice, priceForm.formFields.price.validation)) {
            setFormState({
                ...formState,
                competitorStationProducts: [...otherStationProducts, {competitorStationProductId: priceForm.id, price: parseFloat(formattedPrice)}]
            })
        } else {
            setFormState({
                ...formState,
                competitorStationProducts: [...otherStationProducts]

            })
        }
    }

    const handleOnBlur = (event: React.FormEvent<HTMLInputElement>) => {
        const input = event.currentTarget.value
        const formattedPrice = formatPrice(input)
        validatePrice(formattedPrice, priceForm.formFields.price.validation, setErrorState)
    }

    useEffect(() => {
        if (errorState.isError && !invalidPriceInputIds.includes(priceForm.id)) {
            setInvalidPriceInputIds([...invalidPriceInputIds, priceForm.id])
        } else if (!errorState.isError && invalidPriceInputIds.includes(priceForm.id)){
            setInvalidPriceInputIds(invalidPriceInputIds.filter(id => id !== priceForm.id))
        }
    }, [errorState])

    const errorClasses = "border-redTheme text-redThemeDark placeholder-redThemeDark focus:outline-none focus:ring-redTheme focus:border-redTheme"
    const noErrorClasses = "focus:ring-indigo-500 focus:border-indigo-500 border-gray-300"

    return (
        <div className="mt-5">
            <label htmlFor="price" className="block text-lg font-medium text-gray-900">
                {priceForm.formLabel}
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
                <input
                    type="text"
                    name="price"
                    id="price"
                    className={`block w-full pl-7 pr-12 sm:text-sm rounded-md ${errorState.isError ? errorClasses : noErrorClasses}`}
                    placeholder={priceForm.formFields.price.placeholder}
                    aria-describedby="price-currency"
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    value={priceValue}
                    inputMode="decimal"
                />
                {errorState.isError && (
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <ExclamationCircleIcon className="h-5 w-5 text-redTheme" aria-hidden="true" />
                  </div>
                )}
            </div>
            {errorState && (
              <p className="text-redTheme mt-2 text-sm" id="email-error">
                  {errorState.errorMessage}
              </p>
            )}
        </div>
    );
}