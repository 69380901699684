import {ErrorState, ValidationRule, ValidationRules} from "../types";
import {Dispatch, SetStateAction} from "react";

export const isValidPrice = (
  price: string,
  validation: ValidationRules,
): Boolean => {
    try {
        const newPrice = parseFloat(price.replace(",", "."))
        if (newPrice < validation.minValue.value){
            return false
        } else if (newPrice > validation.maxValue.value){
            return false
        }
        return true
    } catch (error) {
        return false
    }
}

export const validatePrice = (
  price: string,
  validation: ValidationRules,
  setErrorState: Dispatch<SetStateAction<ErrorState>>
) => {
    try {
        const newPrice = parseFloat(price.replace(",", "."))
        if (newPrice == 0.0) {
            // When price is zero no price change is submitted
            setErrorState({ isError: false, errorMessage: undefined })
        }
        else if (newPrice < validation.minValue?.value) {
            setErrorState({ isError: true, errorMessage: validation.minValue?.errorMessage })
        } else if (newPrice > validation.maxValue?.value) {
            setErrorState({ isError: true, errorMessage: validation.maxValue?.errorMessage })
        } else {
            setErrorState({ isError: false, errorMessage: undefined })
        }

    } catch (error) {
        setErrorState({ isError: true, errorMessage: "Ugyldig format" })
    }
}

export const formatPrice = (input: string): string => {
    let numericInput = input
        .replace(/[\D]/g,'') // remove non numeric characters
        .replace(/^0+/, '') // remove leading zeroes

    let formattedInput;
    switch (numericInput.length) {
        case 0:
            formattedInput = "0.00"
            break;
        case 1:
            formattedInput = numericInput
            break;
        case 2:
            formattedInput = `${numericInput.substring(0, 1)}.${numericInput.substring(1, 2)}`
            break;
        case  3:
            formattedInput = `${numericInput.substring(0, 1)}.${numericInput.substring(1, 3)}`
            break;
        default:
            formattedInput = `${numericInput.substring(0, 2)}.${numericInput.substring(2, 4)}`
            break;
    }

    return formattedInput
}