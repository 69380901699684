import React, {FunctionComponent} from 'react';
import "./button.scss";

interface ButtonProps {
    type: "button" | "submit";
    disabled?: boolean;
    onClick?: () => void;
    style?: string;
}
export const Button: FunctionComponent<ButtonProps> = ({ type, disabled = false, onClick, style, children}) => (
    <button
        type={type}
        onClick={onClick}
        disabled={disabled!!}
        className={`${style ?? "" } button mt-8 inline-flex items-center px-6 py-3 border border-transparent text-base font-medium
        rounded-md shadow-sm text-white bg-darkgreyActive hover:bg-darkgreyHover focus:outline-none focus:ring-2
        focus:ring-offset-2 focus:ring-indigo-500`}
    >
        {children}
    </button>

)