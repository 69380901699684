import React, { Component, ErrorInfo, ReactNode } from "react";
import {Header} from "./Header/Header";
import {Alert} from "./Alert/Alert";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false
  };

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div>
          <Header setIsOpen={() => {}}/>
          <Alert
            text="Det har oppstått en uforventet feil, vennligst last inn siden på nytt og prøv igjen. Ta kontakt med en administrator dersom problemet vedvarer."
            color="orange"
            style="ml-2 mr-2"
          />
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;