import {Dispatch, SetStateAction} from "react";
import axios from "axios";
import {AgentStation, CompetitorStation, FetchAsyncState, FetchingDataState, FormResponse} from "../types";

export const getBaseUrl = (): string => process.env.REACT_APP_ENVIRONMENT == "PROD"
  ? 'https://func-agentappapi-prod.azurewebsites.net/api'
  : 'https://func-agentappapi-qa.azurewebsites.net/api';

const getMockUrl = (): string => "./mock/stations.json"

const isLocalDevelopment = false;

export async function fetchStationData(
  token: string,
  setCompetitorStationsState: Dispatch<SetStateAction<CompetitorStation[]>>,
  setAgentStationsState: Dispatch<SetStateAction<AgentStation[]>>,
  setFetchingStationsState: Dispatch<SetStateAction<FetchingDataState>>
) {
  try {
    setFetchingStationsState({ loading: true, error: false, success: false })
    return axios
      .get<FetchAsyncState>(
        isLocalDevelopment ? getMockUrl() : `${getBaseUrl()}/competitor-stations`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      )
      .then(response => {
        setCompetitorStationsState(response.data.competitorStations)
        setAgentStationsState(response.data.agentStations)
        setFetchingStationsState({ loading: false, error: false, success: true })
      })

  } catch (error) {
    setFetchingStationsState({ loading: false, error: true, success: false })
  }
}


export async function postStationForm(token: string, body: FormResponse, setPostFormState: Dispatch<SetStateAction<FetchingDataState>>) {
  try {
    setPostFormState({loading: true, success: false, error: false})
    const {data} = await axios.post<FormResponse>(
      `${getBaseUrl()}/competitor-stations/price-update`,
      body,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Authorization': `Bearer ${token}`
        },
      },
    );

    setPostFormState({loading: false, success: true, error: false})
    return data;

  } catch (error) {
    setPostFormState({loading: false, success: false, error: true})
  }
}