import React, {Dispatch, Fragment, FunctionComponent, SetStateAction, useState} from 'react';
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'

import {AgentStation, FormState} from "../../types";

interface AgentStationProps {
  formState: FormState;
  setFormState: Dispatch<SetStateAction<FormState>>;
  stations?: AgentStation[]
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export const AgentStationDropdown : FunctionComponent<AgentStationProps>= ({
  formState, setFormState, stations
}) => {

  const [selectedStationId, setSelectedStationId] = useState<string | undefined>(undefined)
  const selectedStation = stations?.find(station => station.id === selectedStationId);

  const handleOnSelect = (value: string) => {
    const station = stations.find(station => station.id === value)
    if (station) {
      setFormState({
        ...formState,
        agentStation: station,
        competitorStation: undefined,
        competitorStationProducts: undefined
      })
    } else {
      setFormState({
        ...formState,
        agentStation: undefined,
        competitorStation: undefined,
        competitorStationProducts: undefined
      })
    }
    if(value=="-1"){
      setSelectedStationId(undefined)
    }else {
      setSelectedStationId(value)
    }
  }

  return (
    <>
      {stations?.length > 0 &&
          <Listbox value={selectedStationId} onChange={(value: string) => handleOnSelect(value)}>
            {({open}: { open: boolean }) => (
              <>
                <Listbox.Label className="block text-lg font-medium text-gray-700">Automat 1</Listbox.Label>
                <div className="mt-1 relative">
                  <Listbox.Button
                    className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    <span className="block truncate">{selectedStation ? selectedStation?.name : "Alle stasjoner"}</span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                    </span>
                  </Listbox.Button>
                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-100"
                  >
                    <Listbox.Options
                      style={{backgroundColor: "white"}}
                      className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                    >
                      <Listbox.Option
                          key={-1}
                          className={({active}) =>
                            classNames(
                              active ? 'text-white bg-indigo-600' : 'text-gray-900',
                              'cursor-default select-none relative py-2 pl-3 pr-9'
                            )
                          }
                          value={-1}
                        >
                          {({selected, active}) => (
                            <>
                              <span
                                className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                Alle stasjoner
                              </span>
                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? 'text-white' : 'text-indigo-600',
                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                  )}
                                >
                                  <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      {stations.map((station) => (
                        <Listbox.Option
                          key={station.id}
                          className={({active}) =>
                            classNames(
                              active ? 'text-white bg-indigo-600' : 'text-gray-900',
                              'cursor-default select-none relative py-2 pl-3 pr-9'
                            )
                          }
                          value={station.id}
                        >
                          {({selected, active}) => (
                            <>
                              <span
                                className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                {station.name}
                              </span>
                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? 'text-white' : 'text-indigo-600',
                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                  )}
                                >
                                  <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>}
    </>
  );
}