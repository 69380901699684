import React, {Dispatch, Fragment, FunctionComponent, SetStateAction} from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
  MenuIcon,
  XIcon,
} from '@heroicons/react/outline'
import {useAuth0} from "@auth0/auth0-react";
import './header.scss';

const Logo = require('../../assets/logo_white.png');

interface HamburgerMenuProps {
  setIsOpen: Dispatch<SetStateAction<boolean>>
}
export const Header: FunctionComponent<HamburgerMenuProps> = ({ setIsOpen}) => {
  const { logout } = useAuth0();

  return (
    <header className="header">
      <div className='imageContainer'>
        <img src={Logo}>

        </img>
      </div>
      <button onClick={() => logout({ returnTo: window.location.origin })}>
          Logg ut
      </button>
    </header>
  )
}