import React, {FunctionComponent} from 'react';
import "./alert.scss";

interface AlertProps {
  text: string,
  color: "orange" | "green",
  style?: string
}

export const Alert: FunctionComponent<AlertProps> = ({color, text, style}) => {
  const classes =`${style ?? ""} ${color == "orange" ? "warning-alert" : "success-alert"}`

  return (
    <div className={classes} role="alert">
      <p>{text}</p>
    </div>
  );
}