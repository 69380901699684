import React from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {StationPriceForm} from "./components/StationPriceForm/StationPriceForm";
import {Spinner} from "./components/Spinner";
import {Header} from "./components/Header/Header";
import ErrorBoundary from "./components/ErrorBoundary";

export const App = () => {
  const {isLoading,error, loginWithRedirect, isAuthenticated} = useAuth0();
  if (isLoading) return (
    <main className="main-container flex-col">
      <Header setIsOpen={() => {}}/>
      <Spinner/>
    </main>
    )

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if(!isAuthenticated) {
    loginWithRedirect();
  }else {
    return (
      <ErrorBoundary>
        <main className="main-container">
            <StationPriceForm/>
        </main>
      </ErrorBoundary>
    );
}
}

export default App;
